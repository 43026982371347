import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Tabs,
  Tab,
  Box,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { Close as CloseIcon, MoreVert as MoreVertIcon } from '@material-ui/icons';
import { Notification } from '@apps/shared/src/notification/types/notification';
import { markAllAsRead } from '@apps/shared/src/notification/actions';
import { toggleNotification } from '@apps/shared/src/auth/userActions';
import NotificationItem from './NotificationItem';
import { RootState } from '../store';

const useStyles = makeStyles(theme => ({
  dropdownMenu: {
    marginTop: '3.5rem',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  heading: {
    marginLeft: theme.spacing(2),
    fontWeight: 600,
    fontSize: '1.3rem',
  },
  disableNotification: {
    marginLeft: theme.spacing(2),
    fontSize: '1rem',
  },
  markAllAsRead: {
    color: '#1976D2',
    cursor: 'pointer',
  },
  viewAll: {
    marginTop: theme.spacing(1),
  },
  viewAllText: {
    width: '100%',
    color: '#1976D2',
    fontSize: '0.75rem',
    textAlign: 'end',
    marginRight: '.5rem',
  },
  ellipsesButton: {
    marginLeft: 'auto',
  },
  smallerCloseIcon: {
    fontSize: '1rem',
    marginRight: '.2rem',
  },
  bellIllustration: {
    width: '8rem',
    height: '8rem',
  },
  noNotifications: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '.5rem',
  },
}));

type StateProps = {
  notifications: Notification[];
  notificationsEnabled: boolean;
};

type DispatchProps = {
  markAllAsRead: () => void;
  toggleNotification: (toggleNotification: boolean) => void;
};

type ParentProps = {
  anchorEl: HTMLElement | null;
  handleClose: () => void;
};

type Props = StateProps & DispatchProps & ParentProps;

const mapStateToProps = ({ notification, user }: RootState): StateProps => ({
  notifications: notification.notifications,
  notificationsEnabled: user?.currentUser?.enableNotification || false,
});

const mapDispatchToProps: DispatchProps = {
  markAllAsRead,
  toggleNotification,
};

const NotificationDropdown: React.FC<Props> = ({
  anchorEl,
  handleClose,
  notifications,
  markAllAsRead,
  toggleNotification,
  notificationsEnabled,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [ellipsesAnchorEl, setEllipsesAnchorEl] = useState<HTMLElement | null>(null);
  const classes = useStyles();

  const filteredNotifications = (type: string): Notification[] => {
    const sortedNotifications = notifications.sort(
      (a, b) => new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime()
    );

    switch (type) {
      // case 'Message Center':
      //   return sortedNotifications.filter(
      //     notification => notification.recordType === 'Message Center'
      //   );
      case 'Access Issue':
      case 'Balance Bill':
      case 'Call Ahead':
      case '6DH Employee Call Ahead':
        return sortedNotifications.filter(notification => notification.recordType === 'Others');
      default:
        return sortedNotifications;
    }
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newIndex: number): void => {
    setTabIndex(newIndex);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    setEllipsesAnchorEl(event.currentTarget);
  };

  const handleEllipsesClose = (): void => {
    setEllipsesAnchorEl(null);
  };

  const getFilteredNotifications = (): Notification[] => {
    // if (tabIndex === 1) {
    //   return filteredNotifications('Message Center');
    // }
    if (tabIndex === 1) {
      return filteredNotifications('Others');
    }
    return filteredNotifications('');
  };

  const renderContent = () => {
    if (notificationsEnabled) {
      if (notifications.length > 0) {
        return (
          <Box>
            {getFilteredNotifications().map(notification => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </Box>
        );
      }
      return (
        <div className={classes.noNotifications}>
          <img
            className={classes.bellIllustration}
            src="/images/NotificationBell.png"
            alt="Notification Bell"
          />
          <Typography variant="h6" color="textSecondary" align="center">
            No Notifications
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            You don’t have any notifications at this time.
          </Typography>
          <Typography variant="body2" color="textSecondary" align="center">
            Please come back later.
          </Typography>
        </div>
      );
    }
    return (
      <div className={classes.noNotifications}>
        <img
          className={classes.bellIllustration}
          src="/images/NotificationBell.png"
          alt="Notification Bell"
        />
        <Typography variant="h6" color="textSecondary" align="center">
          Notifications Disabled
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center">
          You have disabled your notifications.
        </Typography>
        <Typography variant="body2" color="textSecondary" align="center">
          Turn them on to see any updates.
        </Typography>
      </div>
    );
  };

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
      className={classes.dropdownMenu}
    >
      <Box className={classes.header}>
        <Typography variant="h5" className={classes.heading}>
          Notifications
        </Typography>
        <IconButton size="small" onClick={handleClose} aria-label="Close">
          <CloseIcon className={classes.smallerCloseIcon} />
        </IconButton>
      </Box>
      <Box className={classes.header}>
        <Typography variant="body1" className={classes.disableNotification}>
          Notifications Settings
        </Typography>
        <IconButton size="small" className={classes.ellipsesButton} onClick={handleClick}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          anchorEl={ellipsesAnchorEl}
          open={Boolean(ellipsesAnchorEl)}
          onClose={handleEllipsesClose}
        >
          {notifications.length !== 0 && notificationsEnabled && (
            <MenuItem
              onClick={() => {
                markAllAsRead();
                handleEllipsesClose();
              }}
            >
              Mark all as read
            </MenuItem>
          )}
          <MenuItem onClick={() => toggleNotification(!notificationsEnabled)}>
            {notificationsEnabled ? 'Disable Notifications' : 'Enable Notifications'}
          </MenuItem>
        </Menu>
      </Box>
      <Tabs value={tabIndex} onChange={handleTabChange} variant="fullWidth">
        <Tab
          label={notificationsEnabled ? `All (${filteredNotifications('').length})` : 'All'}
          style={{ flex: 1 }}
        />
        {/* <Tab
          label={
            notificationsEnabled
              ? `Message Center (${filteredNotifications('Message Center').length})`
              : 'Message Center'
          }
        /> */}
        <Tab
          label={
            notificationsEnabled ? `Others (${filteredNotifications('Others').length})` : 'Others'
          }
          style={{ flex: 1 }}
        />
      </Tabs>
      {renderContent()}
      {notificationsEnabled && notifications.length > 0 && (
        <MenuItem
          component={Link}
          to="/notifications"
          onClick={handleClose}
          className={classes.viewAll}
        >
          <Typography className={classes.viewAllText}>View All</Typography>
        </MenuItem>
      )}
    </Menu>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationDropdown);
