import React, { useCallback } from 'react';
import { makeStyles } from '@material-ui/styles';
import { TextField, IconButton, InputAdornment } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { CaseFilter } from '@apps/shared/src/caseSelect/actions';

import { debounce } from '@apps/shared/src/utilities';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { IncompleteTheme } from '../shared/types/theme';

const styles = makeStyles((theme: IncompleteTheme) => ({
  filterContainer: {
    backgroundColor: '#ffffff',
    height: 'calc(100vh - 70px - 50px - 6rem)',
    padding: '0 1rem',
    zIndex: 0,
  },
  selectBox: {
    width: '100%',
    '& .MuiSelect-selectMenu': {
      backgroundColor: '#fff !important',
      border: '1px solid #E5EBF5',
      width: '100%',
    },
  },
  resetBtn: {
    color: '#ff0000',
    textDecoration: 'underline',
    padding: '0',
    border: 'none',
    backgroundColor: 'white',
    cursor: 'pointer',
  },
  root: {
    width: '100%',
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: '0',
    },
    '& .MuiOutlinedInput-input': {
      height: '40px',
      padding: '0 10px 0 0',
    },
    '& .MuiIconButton-root': {
      paddingRight: '0',
    },
  },
  searchIcon: {
    color: '#F07037',
    fontSize: '22px',
    paddingLeft: '0',
  },
  menuItem: {
    backgroundColor: '#fff',
    color: 'red',
    height: '60px',
    width: '100%',
  },
  selectDiv: {
    width: '100%',
    marginTop: '30px',
  },
  label: {
    color: '#666',
    fontSize: '14px',
    fontFamily: 'Roboto, Sans-serif',
  },
}));

type ParentProps = {
  getMyCases: (filter: CaseFilter) => void;
  resetMyCasesState: () => void;
  appliedFilter: CaseFilter;
  setAppliedFilter: React.Dispatch<React.SetStateAction<any>>;
};

const MyCasesFilter = ({
  getMyCases,
  resetMyCasesState,
  appliedFilter,
  setAppliedFilter,
}: ParentProps) => {
  const classes = styles();
  const debouncedSearch = useCallback(debounce(getMyCases, 3000), [getMyCases]);

  const handleSearch = (event: any) => {
    const { value } = event.target;

    setAppliedFilter((prevState: any) => ({
      ...prevState,
      queryParam: value,
    }));
    const filteredValues = {
      queryParam: value,
      type: appliedFilter.type === 'All' ? '' : appliedFilter.type,
      createdDate: '',
      status: appliedFilter.status === 'All' ? '' : appliedFilter.status,
    };
    debouncedSearch(filteredValues);
  };

  const handleStatusChange = (event: any) => {
    const { value } = event.target;
    setAppliedFilter((prevState: any) => ({
      ...prevState,
      status: value,
    }));
    const filteredValues = {
      queryParam: appliedFilter.queryParam,
      type: appliedFilter.type === 'All' ? '' : appliedFilter.type,
      createdDate: '',
      status: value === 'All' ? '' : value,
    };
    getMyCases(filteredValues);
  };

  const handleCaseTypeChange = (event: any) => {
    const { value } = event.target;
    setAppliedFilter((prevState: any) => ({
      ...prevState,
      type: value,
    }));
    const filteredValues = {
      queryParam: appliedFilter.queryParam,
      type: value === 'All' ? '' : value,
      createdDate: '',
      status: appliedFilter.status === 'All' ? '' : appliedFilter.status,
    };
    getMyCases(filteredValues);
  };

  const resetFilter = () => {
    const initialState = {
      queryParam: '',
      type: 'All',
      createdDate: '',
      status: 'All',
    };
    resetMyCasesState();
    setAppliedFilter(initialState);
    const filteredValues = {
      queryParam: '',
      type: '',
      createdDate: '',
      status: '',
    };
    getMyCases(filteredValues);
  };

  return (
    <>
      <div className={classes.filterContainer}>
        <div style={{ textAlign: 'right', margin: '1rem 0' }}>
          <button onClick={resetFilter} className={classes.resetBtn} type="button">
            Clear
          </button>
        </div>

        <div>
          <TextField
            id="outlined-basic"
            variant="outlined"
            placeholder="Search..."
            type="search"
            value={appliedFilter.queryParam}
            onChange={handleSearch}
            className={classes.root}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton aria-label="Search">
                    <Search className={classes.searchIcon} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className={classes.selectDiv}>
          <p className={classes.label}>Status</p>
          <Select
            id="demo-simple-select-outlined"
            value={appliedFilter.status}
            onChange={handleStatusChange}
            className={classes.selectBox}
          >
            <MenuItem value="All" className={classes.menuItem}>
              All
            </MenuItem>
            <MenuItem value="provider negotiation">Processing</MenuItem>
            <MenuItem value="intake">Opened</MenuItem>
            <MenuItem value="closed">Closed</MenuItem>
          </Select>
        </div>

        <div className={classes.selectDiv}>
          <p className={classes.label}>Case Type</p>
          <Select
            id="demo-simple-select-outlined"
            value={appliedFilter.type}
            onChange={handleCaseTypeChange}
            className={classes.selectBox}
          >
            <MenuItem value="All" className={classes.menuItem}>
              All
            </MenuItem>
            <MenuItem value="BalanceBill">Balance Bill</MenuItem>
            <MenuItem value="AccessIssue">Access Issue</MenuItem>
            <MenuItem value="CallAhead">Call Ahead</MenuItem>
          </Select>
        </div>
      </div>
    </>
  );
};

export default MyCasesFilter;
