import React from 'react';
import { useHistory } from 'react-router-dom';

import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';

import { IncompleteTheme } from './types/theme';

const styles = makeStyles((theme: IncompleteTheme) => ({
  contactUs: {
    display: 'flex',
    justifyContent: 'center',
    gap: '2rem',
    lineHeight: '1.465rem',
  },
  labelStyle: {
    fontSize: '1rem',
    fontWeight: 400,
    color: '#747474',
    borderRadius: 0,
    padding: '0 0 4px 0',
    cursor: 'pointer',
  },
  copyright: {
    fontWeight: 400,
    fontSize: '1rem',
    color: '#888787',
    lineHeight: '1.318rem',
    textAlign: 'center',
  },
  sixDegreeBtn: {
    fontSize: '1rem',
    borderRadius: 0,
    padding: '0 0 4px 0',
    color: '#F07037',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  bottomCredits: {
    bottom: 0,
    width: '100%',
  },
}));
const BottomCredits = () => {
  const classes = styles();
  const history = useHistory();

  return (
    <div className={classes.bottomCredits}>
      <p className={classes.contactUs}>
        <IconButton className={classes.labelStyle} onClick={() => history.push('/contact-us')}>
          Contact Us
        </IconButton>
        <IconButton className={classes.labelStyle} onClick={() => history.push('/privacy-policy')}>
          Privacy Policy
        </IconButton>
        <IconButton className={classes.labelStyle} onClick={() => history.push('/FAQ')}>
          FAQs
        </IconButton>
      </p>
      <p className={classes.copyright}>
        <span>&#169; 2023 </span>
        <IconButton className={classes.sixDegreeBtn}>
          <a
            href="https://www.6degreeshealth.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#F97140' }}
          >
            6 Degrees Health
          </a>
        </IconButton>
        <span> All Rights Reserved</span>
      </p>
    </div>
  );
};

export default BottomCredits;
